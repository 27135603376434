import * as React from 'react'
import { Link } from 'react-scroll'

import sparkles from '../img/sparkles.png'
import darkMode from "../img/darkMode.png"

function Menu() {
    return (
        <div className='mr-40 relative flex gap-x-12'>
            <Link to="competences" smooth={true} duration={1000} offset={-50}><a href="#competences">Compétences</a></Link>
            <Link to="experiences" smooth={true} duration={1000} offset={-50}><a href="#experiences">Expériences</a></Link>
            <Link to="projets" smooth={true} duration={1000} offset={-50}><a href="#projets">Projets</a></Link>
            <Link to="contact" smooth={true} duration={1000} offset={-50}><a href="#contact">Contact</a></Link>
        </div>
    )
}

function Header() {
    let name : string = 'Maxime Gonzalez'
    return (
        <nav className="sticky top-0 w-full shadow-md h-16 bg-my-white relative flex items-center text-raisin-black justify-between z-50 dark:bg-black dark:text-my-white">
            <div className='ml-20 relative flex'>
                <img className="size-8" src={sparkles} alt="sparkles"></img>
                <p className="ml-4 text-2xl">{name}</p>
            </div>
            <div className="flex justify-normal items-center">
                <Menu></Menu>
                <button className='pr-5' onClick={() => document.documentElement.classList.toggle("dark")}>
                    <img className='h-7 dark:invert' src={darkMode} alt="darkMode"/>
                </button>
            </div>
        </nav>
    )
}

export default Header