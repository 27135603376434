import './App.css';
import "./tailwind.css";

import Header from './components/header.tsx';
import Main from './components/main.tsx';
import Footer from './components/footer.tsx';

function App() {
  return (
    <>
      <Header/>
      <Main/>
      <Footer/>
    </>
  );
}

export default App;
