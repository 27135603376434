import * as React from 'react'

import { skill } from '../type.ts';

import pictureProfile from '../img/pictureProfile.jpg';
import astekLogo from '../img/astekLogo.png'
import eLearningTouchLogo from '../img/eLearningTouchLogo.png'
import screenPortfolio from '../img/screenPortfolio.png'

import linkedinIcon from '../img/linkedinIcon.png'
import mailIcon from '../img/mailIcon.png'

import cssLogo from "../img/skillsLogos/css.svg"
import htmlLogo from "../img/skillsLogos/html.png"
import javaLogo from "../img/skillsLogos/java.png"
import jsLogo from "../img/skillsLogos/js.png"
import vueLogo from "../img/skillsLogos/vue.png"
import pythonLogo from "../img/skillsLogos/python.png"
import reactLogo from "../img/skillsLogos/react.png"
import tailwindLogo from "../img/skillsLogos/tailwind.svg"
import tsLogo from "../img/skillsLogos/ts.png"
import vbLogo from "../img/skillsLogos/vb.png"
import phpLogo from "../img/skillsLogos/php.png"
import linuxLogo from "../img/skillsLogos/linux.png"
import windowsLogo from "../img/skillsLogos/windows.png"
import vsLogo from "../img/skillsLogos/vs.png"
import vscodeLogo from "../img/skillsLogos/vscode.webp"
import wordpressLogo from "../img/skillsLogos/wordpress.webp"
import githubLogo from "../img/skillsLogos/github.png"
import gitlabLogo from "../img/skillsLogos/gitlab.png"
import sqlLogo from "../img/skillsLogos/sql.png"

const css: skill = {logo : cssLogo, label: "CSS" }
const html: skill = {logo : htmlLogo, label: "HTML" }
const java: skill = {logo : javaLogo, label: "JAVA" }
const js: skill = {logo : jsLogo, label: "JavaScript" }
const vue: skill = {logo : vueLogo, label: "Vue.js" }
const python: skill = {logo : pythonLogo, label: "Python" }
const react: skill = {logo : reactLogo, label: "React" }
const tailwind: skill = {logo : tailwindLogo, label: "Tailwind" }
const typescript: skill = {logo : tsLogo, label: "TypeScript" }
const vb: skill = {logo : vbLogo, label: "VisualBasic.NET" }
const php: skill = {logo : phpLogo, label: "PHP" }
const linux: skill = {logo : linuxLogo, label: "Linux" }
const windows: skill = {logo : windowsLogo, label: "Windows" }
const vs: skill = {logo : vsLogo, label: "Visual Studio" }
const vscode: skill = {logo : vscodeLogo, label: "Visual Studio Code" }
const wordpress: skill = {logo : wordpressLogo, label: "WordPress" }
const github: skill = {logo : githubLogo, label: "GitHub" }
const gitlab: skill = {logo : gitlabLogo, label: "GitLab" }
const sql : skill = {logo : sqlLogo, label: "SQL"}
const skills : skill[] = [css, html, java, js, vue, python, react, tailwind, typescript, vb, php, linux, windows, vs, vscode, wordpress, github, gitlab, sql]

function Portrait() {
    return(
       <section className='flex h-screen w-full justify-center items-center text-raisin-black dark:bg-raisin-black dark:text-my-white'>
            <div className='flex items-center justify-evenly w-full h-full -translate-y-10'>
                <div>
                    <h2 className='-mb-" ml-1 text-2xl font-semibold tracking-wider'>Bienvenue sur le portfolio de</h2>
                    <h1 className='text-8xl font-bold tracking-widest underline decoration-hunyadi-yellow decoration-from-font underline-offset-8' >Maxime Gonzalez</h1>
                </div>
                <img className='h-96 rounded-full' src={pictureProfile} alt="Moi"/>
            </div>
       </section>
    )
   }

function Competences() {
    const [skillsCards] = React.useState(
        skills.map(skill => (
            <div className="border-2 border-my-white h-10 flex text-lg items-center m-3 p-3 rounded-xl transition-all hover:text-dark-spring-green hover:bg-my-white" key={skill.label}>
                <img className='h-5 mr-2' src={skill.logo} alt="Logo"/>
                <p>{skill.label}</p>            
            </div>
        ))
);
    return(
        <section id='competences' className='flex w-full justify-center items-center bg-dark-spring-green'>
            <div className='text-center text-my-white w-7/12'>
                <h1 className="text-5xl tracking-widest my-20">Je maitrise :</h1>
                <div className="flex flex-wrap m-20 justify-center" children={skillsCards}/>
            </div>
        </section>
    )
}

function Experiences() {
    return(
        <section id='experiences' className='flex w-full justify-center items-center text-raisin-black dark:bg-raisin-black dark:text-my-white'>
            <div className='text-center m-28'>
                <h1 className='text-5xl tracking-widest'>Mes expériences</h1>
                <div className='text-left text-lg'>
                    <div className='relative mt-24 px-5 py-3 border-l-2 border-hunyadi-yellow w-full'>
                    <img className='absolute h-16 right-0 mt-4' src={astekLogo} alt="Logo Astek"/>
                        <h3 className='text-md text-hunyadi-yellow tracking-wide'>Stage</h3>
                        <h2 className='font-semibold'>- Développeur Front-End</h2>
                        <a href='https://astekgroup.fr/' target='_blank'  rel="noreferrer">Astek</a>
                        <p>📅 8 janv. 2024 - 16 fév. 2024 (6 semaines)</p>
                        <p>🎯 Refonte d'une application de gestion de fichier pour le Crédit Mutuel Arkéa</p>
                    </div>
                    <div className='relative mt-24 px-5 py-3 border-l-2 border-hunyadi-yellow w-full'>
                        <img className='absolute h-16 right-0 mt-4' src={eLearningTouchLogo} alt="Logo E-learning Touch'"/>
                        <h3 className='text-md text-hunyadi-yellow tracking-wide'>Stage</h3>
                        <h2 className='font-semibold'>- Développeur Front-End</h2>
                        <a href='https://www.elearningtouch.com/'  target='_blank'  rel="noreferrer">E-learning Touch'</a>
                        <p>📅 22 mai 2023 -  23 juin 2023 (5 semaines)</p>
                        <p>🎯 Début du développement d'une marketplace (<a className='underline decoration-hunyadi-yellow hover:decoration-2' href='https://elearningboulevard.com/' target='_blank' rel="noreferrer">E-learning Boulevard</a>) avec Wordrpress</p>
                        <p>🎯 Amélioration d'un plugin <a href='https://moodle.org' target='_blank' rel="noreferrer">Moodle</a></p>
                    </div>
                </div>
            </div>
        </section>
    ) }

function Projets() {
    return(
        <section id='projets' className='flex w-full justify-center items-center bg-hunyadi-yellow dark:bg-darkMode-hunyadi-yellow'>
            <div className='text-center text-raisin-black my-24'>
                <h1 className='text-5xl tracking-widest'>Mes projets</h1>
                <div className='mt-20 w-full grid grid-cols-3 text-left text-lg'>
                    <div></div>
                    <div className='m-5 p-5 border-white border-r-4 border-b-4 rounded-md'>
                        <h3 className='uppercase tracking-widest font-bold text-white text-xl'>Portfolio</h3>
                        <p className='text-base'>🔗 <a className="hover:underline decoration-white" href="https://www.mgonzalez.ovh/" target='_blank' rel="noreferrer">mgonzalez.ovh</a></p>
                        <p className='my-5 text-pretty'>Débuté en mars 2024, c'est mon premier projet perso, il a pour objectif d'évoluer sans cesse. C'est mon premier terrain d'entrainement et ma principale vitrine. Il est developpé avec React.</p>
                        <a href="https://www.mgonzalez.ovh/" target='_blank' rel="noreferrer"><img src={screenPortfolio} alt='Screen Portfolio'/></a>
                    </div>
                    <div></div>
                </div>
            </div>
        </section>
    )
}

function Contact() {
    return(
        <section id='contact' className='flex w-full justify-center items-center text-raisin-black dark:bg-raisin-black dark:text-my-white'>
            <div className='text-center  my-16'>
                <h1 className='text-5xl tracking-widest'>Contact</h1>
                <div className='flex justify-center mt-20 items-center'>
                    <a className="active:invert" href='mailto:maximegonzalezbzh@gmail.com' target="_blank" rel="noreferrer">
                        <img className='h-20 dark:invert' src={mailIcon} alt='Mail'/>
                    </a>
                    <p className='mx-8 text-2xl tracking-wider'>ou</p>
                    <a className="active:invert" href="https://www.linkedin.com/in/maxime-gonzalez-bretagne/" target="_blank" rel="noreferrer">
                        <img className='h-20' src={linkedinIcon} alt="Linkedin"/>
                    </a>
                </div>
            </div>
        </section>
    )
}

function Main() {
    return (
        <>
            <Portrait/>
            <Competences/>
            <Experiences/>
            <Projets/>
            <Contact/>
        </>
    )
}

export default Main