import * as React from 'react'

import linkedinIcon from '../img/linkedinIcon.png'
import githubIcon from '../img/githubIcon.svg'

function Credit() {
    const [annee] = React.useState(new Date().getFullYear())
    return (
        <div className='pl-12'>
            <p>© Maxime Gonzalez - {annee}</p>
        </div>
    )
}

function SocialMedia() {
    return (
        <div className='flex'>
            <a className="active:invert dark:invert" href="https://github.com/Maxime-Gonzalez" target="_blank" rel="noreferrer">
                <img className="h-8 pr-5" src={githubIcon} alt='gitHubIcon'/>
            </a>
            <a className="active:invert" href="https://www.linkedin.com/in/maxime-gonzalez-bretagne/" target="_blank" rel="noreferrer">
                <img className="h-8 pr-5" src={linkedinIcon} alt='linkedinIcon'/>
            </a>
        </div>
    )
}

function Footer() {
    return (
        <div className='bottom-0 w-full h-32 bg-my-white items-center flex justify-between dark:bg-black dark:text-my-white'>
            <Credit/>
            <SocialMedia/>
        </div>
    )
}

export default Footer